import { useLoans } from 'hooks/useLoans'
import AppLayout from 'layouts/AppLayout'
import { _formatters } from '@redsales/ds/utils'
import EditButton from 'components/EditButton'
import { navigate } from 'gatsby'
import CardCarousel from 'components/CardCarousel'
import LoanCard from './components/LoanCard'

import './LoanSelection.scss'
import makeTrackings from 'utils/make/trackings'
import { loansFormId } from 'utils/make/constants'

const { toCurrency } = _formatters

const LoanSelection = () => {
  const { loanValue } = useLoans()

  return (
    <AppLayout
      contentHeaderInfos={{
        title: 'Aqui está!',
        description: (
          <>
            As melhores opções de oferta para você com o valor de{' '}
            <strong>{toCurrency(loanValue)}</strong>.
          </>
        ),
        content: <EditButton onEdit={() => navigate(-1)} />,
        onTrack: () => {
          makeTrackings.formBacked({
            formId: loansFormId,
            formName: 'loans',
            stepId: '1edeffb7-da9e-41b5-ae99-ca97391978b8',
            stepName: 'loans-subproducts',
            stepNumber: 3,
          })
        },
      }}
      layoutClassName="loan-selection-layout"
      contentClassName="loan-selection"
      containerClassName="loan-container"
    >
      <h2 className="loan-selection__subtitle">Ideal para pagar dívidas</h2>
      <CardCarousel
        cards={[
          <LoanCard
            key="personal-credit"
            id="personal-credit"
            icon="person"
            title="Crédito Pessoal"
            description="Até 3 meses para começar a pagar."
            content={{
              title: (
                <>
                  <span>R$</span> 55.000,00
                </>
              ),
              titleDetail: 'limite máx. R$ 1000.000,00',
              description: '60x de R$ 1.050,00',
              descriptionDetail: 'taxa de 3,61% ao mês',
            }}
            loanData={{
              id: 'personal-credit',
              category: 'paying-debts',
              type: 'Crédito Pessoal',
              value: 'R$ 55.000,00',
              conditions: '60x de R$ 1.050,00',
              interestRate: '3,61%',
              interestAndIof: '102,61%',
            }}
          />,
          <LoanCard
            key="consignado"
            id="consignado"
            icon="wallet-black"
            title="Renovação consignado"
            description="Mais tempo para pagar com redução de taxas de juros."
            content={{
              title: 'Refinanciar',
              description: (
                <>
                  <span>Refinancie seu</span> crédito consignado{' '}
                  <span>na RVBR e</span> aproveite as vantagens
                </>
              ),
            }}
            loanData={{
              id: 'consignado',
              category: 'paying-debts',
              type: 'Renovação consignado',
              conditions: 'Refinanciar',
            }}
          />,
          <LoanCard
            key="fgts-withdraw"
            id="fgts-withdraw"
            icon="reader"
            title="Saque FGTS antecipado"
            description="Saque FGTS antecipado"
            content={{
              title: (
                <>
                  <span>R$</span> até 70.000,00
                </>
              ),
              description: (
                <>
                  <span>Faça a antecipação do Saque-Aniversário com</span> taxas
                  a partir de 1,49%
                </>
              ),
            }}
            loanData={{
              id: 'fgts-withdraw',
              category: 'paying-debts',
              type: 'Saque FGTS antecipado',
              value: 'até R$ 70.000,00',
              interestRate: 'a partir de 1,49%',
            }}
          />,
        ]}
      />

      <h2 className="loan-selection__subtitle">Para financiar seus estudos</h2>
      <LoanCard
        id="fin-uni"
        icon="school"
        title="Financiamento Universitário"
        description="Até 12 meses para pagar o valor do semestre."
        className="loan-selection__fin-uni"
        content={{
          title: (
            <>
              <span>R$</span> 56.123,02
            </>
          ),
          titleDetail: 'Valor IOF: R$ 1.123,02',
          description: '12x de R$ 5.365,57',
          descriptionDetail: 'taxa de 3,61% ao mês',
        }}
        loanData={{
          id: 'fin-uni',
          category: 'studies-financing',
          type: 'Financiamento Universitário',
          value: 'R$ 56.123,02',
          conditions: '12x de R$ 5.365,57',
          interestRate: '3,61%',
          interestAndIof: '102,61%',
        }}
      />

      <h2 className="loan-selection__subtitle">Comprar bens de alto valor</h2>
      <CardCarousel
        cards={[
          <LoanCard
            key="imob"
            id="imob"
            icon="home"
            title="Crédito Imobiliário"
            description="Taxa fixa. Você sabe quanto vai pagar do início ao fim."
            content={{
              title: (
                <>
                  <span>parcela de R$</span> 1.934,40
                </>
              ),
              titleDetail: 'financiamento em 35 anos/máx',
              description: 'última parcela R$ 527,00',
              descriptionDetail: 'taxa de 6,34% ao ano',
            }}
            loanData={{
              id: 'imob',
              category: 'high-value-goods',
              type: 'Crédito Imobiliário',
              installment: 'R$ 1.934,40',
              deadline: '35 anos/máx',
              lastInstallment: 'R$ 527,00',
              interestRate: '6,34%',
            }}
          />,
          <LoanCard
            key="vehicle"
            id="vehicle"
            icon="calendar-black"
            title="Financiamento de veículos"
            description="As melhores opções para conversar com o lojista."
            content={{
              title: (
                <>
                  <span>R$</span> 59.000,00
                </>
              ),
              titleDetail: 'valor de entrada: R$ 10.000,00',
              description: '48x de R$ 1.200,00',
              descriptionDetail: 'custo efetivo anual (CET) de 3,56%',
            }}
            loanData={{
              id: 'vehicle',
              category: 'high-value-goods',
              type: 'Financiamento de veículos',
              value: 'R$ 59.000,00',
              entry: 'R$ 10.000,00',
              installment: '48x de R$ 1.200,00',
              anualCET: '3,56%',
            }}
          />,
        ]}
      />
    </AppLayout>
  )
}

export default LoanSelection
